import { useRouter } from "next/router";
import usePopupActions, {
  GUEST_LOGIN_POPUP,
  INPUT_FORM_POPUP,
  LOGIN_POPUP,
  MESSAGE_POPUP,
  PRO_REGISTER_POPUP,
  REGISTER_POPUP,
} from "@/store/actions/popupAction";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import {
  UserProfileFormData,
  UserProfilePopupData,
} from "./useCourseStatusActionMapping.model";
import PaymentService from "@/Logic/ClientLogic/PaymentService/PaymentService";
import UserJobProfileController from "@/Logic/ClientLogic/UserJobProfile.controller";
import useScroll from "@/hooks/useScroll";
import { toast } from "react-toastify";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import { FLOWTYPES } from "@/Logic/ClientLogic/Services/UserFlowService/UserFlowService.types";
import PopupService from "@/Logic/ClientLogic/Services/PopupService";
import { AnalyticsManager } from "@/outscal-commons-frontend";
import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";

const userJobProfileObj = new UserJobProfileController();

const useCourseStatusActionMapping = (currentCourse, paymentType) => {
  const { user, getAndSetUserCourseStatuses, updateUserProfile } =
    useUserActions();
  const router = useRouter();

  const { showPopup, hidePopups } = usePopupActions();
  const { scrollToSectionById } = useScroll();

  const navigate = ({ link, openByWindow }) => {
    if (openByWindow) {
      window.open(link, "_self");
    } else {
      router.push(link, undefined, { shallow: true });
    }
  };

  const takeActionPostStatusUpdate = (updateStatus) => {
    const { link, action } =
      UserCourseService.instance.userCourseController.getActionPostStatusUpdate(
        updateStatus,
        user
      );

    if (link) {
      navigate({ link });
    }

    if (action && actionMapping[action]) {
      actionMapping[action]();
    }
  };

  const setCourseStatusParam = (courseSlug, waitlistSource) => {
    const courseStatusParam = {
      courseSlug,
      waitlistSource,
    };
    localStorage.setItem(
      "courseStatusParam",
      JSON.stringify(courseStatusParam)
    );
  };

  const logoutUserAction = (popupHeading, waitlistSource) => {
    PopupService.instance.showPopup(GUEST_LOGIN_POPUP, {
      actions: {
        joiningCourse: {
          course_slug: currentCourse.slug,
          course_type: currentCourse.course_type,
          waitlistSource,
        },
      },
      onGuestLoggedIn: (user, userCourseStatus) => {
        UserCourseService.instance.setUpUserCourseController(user);
        takeActionPostStatusUpdate(userCourseStatus?.current_status?.status);
      },
      heading: popupHeading || "Enroll Now",
    });
    setCourseStatusParam(currentCourse.slug, waitlistSource);
  };

  const openUserDetailsPopup = (withEMI) => {
    const formData = {};
    if (!user?.userJobProfile?.name) {
      formData.name = UserProfileFormData.name;
    }
    if (!user?.userJobProfile?.phone_number) {
      formData.phone_number = UserProfileFormData.phone_number;
    }
    if (user?.userJobProfile?.billing_country_name == undefined) {
      formData.billing_country_name = UserProfileFormData.billing_country_name;
    }

    showPopup(INPUT_FORM_POPUP, {
      ...UserProfilePopupData,
      analyticsIntentText: "payment_user_details",
      formBuilder: {
        formData,
        submitText: "Submit",
        onSubmit: (e) => onSubmitUserDetails(e, withEMI),
      },
    });
  };

  const onSubmitUserDetails = async (updateProfile, withEMI) => {
    if (updateProfile.billing_country_name) {
      updateProfile.billing_country_name =
        updateProfile.billing_country_name.id;
    }

    hidePopups();

    const updatedProfile = await userJobProfileObj.updateUserProfile(
      updateProfile
    );
    updateUserProfile();
    makePayment(user?.userDetails?.email, updatedProfile, withEMI);
  };

  const onSuccessPayment = async (verifiedStatus, userDetails) => {
    if (!user?.userDetails) {
      toast.success("Payment Successfull");
      if (verifiedStatus) {
        showPopup(LOGIN_POPUP, {
          showFields: true,
          defaultValues: { email: userDetails?.email },
          heading: "Access Granted!",
          subHeading: `Hey ${userDetails?.name}! Your content access has been unlocked, please login to access the content`,
        });
      } else {
        showPopup(PRO_REGISTER_POPUP, {
          showFields: true,
          heading: "Access Granted!",
          subHeading: `Hey ${userDetails?.name}! Please create an account using this email to access the unlocked content`,
          defaultValues: { email: userDetails?.email },
          showFeaturesInitially: false,
          showFields: true,
        });
      }
    } else {
      toast.success("Payment Successfull, reloading...");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const makePayment = async (email, userJobProfile, withEMI) => {
    try {
      await PaymentService.makeCoursePayment(
        {
          courseSlug: UserCourseService.instance?.course?.slug,
          title: UserCourseService.instance?.course?.title,
        },
        paymentType,
        {
          name: userJobProfile?.name,
          email,
          phoneNumber: userJobProfile?.phone_number,
          billingCountry: userJobProfile?.billing_country_name,
        },
        onSuccessPayment,
        withEMI,
        !user?.userDetails && localStorage.getItem("__anon_id")
          ? JSON.parse(localStorage.getItem("__anon_id"))
          : ""
      );
    } catch (error) {
      console.log(error);
    }
  };

  const actionMapping = {
    logout: ({ popupHeading = "Enroll Now", waitlistSource }) => {
      logoutUserAction(popupHeading, waitlistSource);
    },
    "check-make-payment": async ({ withEMI = false }) => {
      if (
        user?.userJobProfile?.name &&
        user?.userJobProfile?.phone_number &&
        user?.userJobProfile?.billing_country_name
      ) {
        makePayment(user?.userDetails?.email, user?.userJobProfile, withEMI);
      } else {
        openUserDetailsPopup(withEMI);
      }
    },
    "update-status": async (params) => {
      const resp =
        await UserCourseService.instance.userCourseController.updateUserCourseStatus(
          params
        );
      getAndSetUserCourseStatuses();

      if (params?.courseType == "PAID-PROJECT") {
        return;
      }
      ClientAppManager.instance.userFlowManager?.checkAndTriggerFlow(
        FLOWTYPES.JOIN_WAITLIST,
        { waitlistedCourses: [params.courseSlug] }
      );
      takeActionPostStatusUpdate(resp?.current_status?.status);
    },
    "scroll-to-top": () => {
      scrollToSectionById("enroll");
    },
    navigate,
    "scroll-pricing": () => {
      scrollToSectionById("pricing");
    },
    "start-course": async () => {
      try {
        const resp =
          await UserCourseService.instance.userCourseController.updateUserCourseStatus(
            {
              courseSlug: UserCourseService.instance?.course?.slug,
            }
          );
        getAndSetUserCourseStatuses();

        if (
          resp?.current_status?.status ==
          UserCourseStatusEnum.getValue("started")
        ) {
          navigate({
            link:
              UserCourseService.instance.courseFromStrapi?.firstMaterialUrl ||
              UserCourseService.instance.course?.previewLink,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  };

  const takeGivenAction = (action) => {
    if (action && action.name) {
      actionMapping[action.name](action.params);
    }
  };

  return { takeGivenAction };
};

export default useCourseStatusActionMapping;
